import {
	Box,
	Card,
	CardContent,
	IconButton,
	useMediaQuery,
	useTheme,
} from "@mui/material";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import NumberCounter from "../../../components/NumberCounter";

const StyledCard = styled(Card)(({ isMobile }) => ({
	borderRadius: "8px",
	backgroundColor: "#FFFFFF1A",
	color: "#fff",
	minHeight: isMobile ? "auto" : "115px",
}));

const DashboardCard = ({
	label,
	icon,
	value,
	isPost = false,
	onClick,
	scrollToSection = false,
}) => {
	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down("md"));

	return (
		<StyledCard
			isMobile={isMobile}
			onClick={
				isPost
					? () => {
							scrollToSection("feed-section");
					  }
					: onClick
			}
		>
			<CardContent>
				{!isPost && (
					<Box
						sx={{
							display: "inline-flex",
							marginTop: "10px",
							alignItems: "center",
							gap: "10px",
							width: "100%",
						}}
					>
						<IconButton style={{ padding: 0, paddingRight: 6 }}>
							<img
								src={icon}
								alt=""
								style={{
									height:
										label === "Team Miles Saved" || label === "Miles Saved"
											? "auto"
											: "25px",
									width:
										label === "Team Miles Saved" || label === "Miles Saved"
											? "35px"
											: "auto",
								}}
							/>
						</IconButton>

						<Box
							sx={{
								display: "flex",
								width: "100%",
								justifyContent: "space-between",
							}}
						>
							<Typography
								fontFamily={"Roboto"}
								fontWeight={500}
								fontSize={"18px"}
								color={"#fff"}
								lineHeight={"24px"}
							>
								{label}
							</Typography>

							{label === "Team Score" || label === "Leaderboard" ? (
								<Typography
									fontFamily={"Inter"}
									fontWeight={700}
									fontSize={"20px"}
									color={"#fff"}
									lineHeight={"24px"}
								>
									{value}
								</Typography>
							) : (
								<NumberCounter targetNumber={value} />
							)}
						</Box>
					</Box>
				)}

				{isPost && (
					<Box
						sx={{
							display: "inline-flex",
							marginTop: "10px",
							alignItems: "center",
							gap: "10px",
						}}
					>
						<IconButton style={{ padding: 0, paddingRight: 6 }}>
							<img src={icon} alt="" style={{ height: "25px" }} />
						</IconButton>

						<Typography
							fontFamily={"Roboto"}
							fontWeight={500}
							fontSize={"18px"}
							color={"#fff"}
							lineHeight={"24px"}
						>
							{label}
						</Typography>

						<Typography
							fontFamily={"Inter"}
							fontWeight={700}
							fontSize={"20px"}
							color={"#fff"}
							lineHeight={"24px"}
						>
							{value}
						</Typography>
					</Box>
				)}
			</CardContent>
		</StyledCard>
	);
};

export default DashboardCard;
