import { Grid, Button } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import FireIcon from "../../../assets/icons/dashboard/fire.svg";
import StarIcon from "../../../assets/icons/dashboard/star.svg";
import CarIcon from "../../../assets/icons/dashboard/car.svg";
import DashboardCard from "./DashboardCard";
import { getUserDetailsAction } from "../../../actions/auth";
import { Modal, Box, Typography } from "@mui/material";
import { getTeamAction } from "../../../actions/team";
import TeamIcon from "../../../assets/icons/dashboard/team.png";
import { useNavigate } from "react-router-dom";
import Asset14 from "../../../assets/icons/dashboard/Asset 14.png";
import Asset6 from "../../../assets/icons/dashboard/Asset 6.png";
import Asset13 from "../../../assets/icons/dashboard/Asset 13.png";
import Asset15 from "../../../assets/icons/dashboard/Asset 15.png";

const DashboardCardsComponent = ({ isMobile, scrollToSection }) => {
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const userDetails = useSelector((state) => state.auth.user);
	const dashboardRefresh = useSelector((state) => state.dashboardRefresh);
	const posts = useSelector((state) => state?.posts);
	const [modalOpen, setModalOpen] = useState(false);
	const [team, setTeam] = useState([]);
	const [alignment, setAlignment] = useState("first");
	const handleOpen = () => setModalOpen(true);
	const handleClose = () => setModalOpen(false);

	useEffect(() => {
		dispatch(getUserDetailsAction())
			.then((res) => {
				// console.log(res);
			})
			.catch((err) => {
				console.log(err);
			});
	}, [posts]);

	useEffect(() => {
		dispatch(getTeamAction())
			.then((res) => {
				setTeam(res.data);
			})
			.catch((err) => {
				console.log(err);
			});
	}, []);

	const handleAlignment = (alignment) => {
		setAlignment(alignment);
	};

	const gridItems = [
		{ label: "Your Score", icon: StarIcon, value: userDetails?.score },
		{ label: "Daily Streak", icon: FireIcon, value: userDetails?.streak || 0 },
		{
			label: "Miles Saved",
			icon: CarIcon,
			value: userDetails?.carbon?.toFixed(2),
			condition: team?.team?.days_to_unlock >= 14,
			onClick: handleOpen,
		},
		{
			label: "Team Score",
			icon: StarIcon,
			value: `${userDetails?.team_score}/${userDetails?.target}`,
		},
		// { label: "Target", icon: StarIcon, value: `${userDetails?.target}` },
	];

	const gridItemsTwo = [
		{
			label: "Team Score",
			icon: StarIcon,
			value: `${userDetails?.team_score}/${userDetails?.target}`,
		},
		{
			label: "Team Miles Saved",
			icon: CarIcon,
			value: userDetails?.carbon?.toFixed(2),
			condition: team?.team?.days_to_unlock >= 14,
			onClick: handleOpen,
		},
		// { label: "Target", icon: StarIcon, value: `${userDetails?.target}` },
	];

	const itemsToShow =
		alignment === "first" ? gridItems.slice(0, 3) : gridItemsTwo;

	return (
		<Box>
			<Box
				display="flex"
				justifyContent="center"
				alignItems="center"
				sx={{ height: "100px", marginBottom: 2 }}
			>
				<Box
					sx={{
						display: "flex",
						gap: "2px",
						backgroundColor: "#FFFFFF1A",
						borderRadius: 2,
						padding: "4px 2px",
					}}
				>
					<Box
						sx={{
							display: "flex",
						}}
					>
						<Button
							sx={{
								borderRadius: 2,
								backgroundColor: alignment === "first" ? "#000" : "transparent",
								"&:hover": {
									backgroundColor:
										alignment === "first" ? "#000" : "transparent",
									boxShadow: "none",
								},
							}}
							disableRipple
							onClick={() => handleAlignment("first")}
						>
							{alignment === "first" ? (
								<img
									src={Asset13}
									alt=""
									style={{
										height: "20px",
										width: "15px",
									}}
								/>
							) : (
								<img
									src={Asset15}
									alt=""
									style={{
										height: "20px",
										width: "15px",
									}}
								/>
							)}
						</Button>
					</Box>

					<Box
						sx={{
							display: "flex",
						}}
					>
						<Button
							sx={{
								borderRadius: 2,
								backgroundColor: alignment === "last" ? "#000" : "transparent",
								"&:hover": {
									backgroundColor:
										alignment === "last" ? "#000" : "transparent",
									boxShadow: "none",
								},
							}}
							disableRipple
							onClick={() => handleAlignment("last")}
						>
							{alignment === "last" ? (
								<img
									src={Asset6}
									alt=""
									style={{
										height: "20px",
										width: "30px",
									}}
								/>
							) : (
								<img
									src={Asset14}
									alt=""
									style={{
										height: "20px",
										width: "30px",
									}}
								/>
							)}
						</Button>
					</Box>
				</Box>
			</Box>

			<Grid container direction={isMobile ? "column" : "row"}>
				<Grid container spacing={isMobile ? 2 : 4}>
					{itemsToShow.map((item, index) =>
						item.condition !== undefined ? (
							item.condition && (
								<Grid
									item
									xs={12}
									md={4}
									lg={3}
									key={index}
									style={{ cursor: "pointer" }}
								>
									<DashboardCard
										label={item.label}
										icon={item.icon}
										value={item.value}
										onClick={item.onClick}
									/>
								</Grid>
							)
						) : (
							<Grid item xs={12} md={4} lg={3} key={index}>
								<DashboardCard
									label={item.label}
									icon={item.icon}
									value={item.value}
								/>
							</Grid>
						)
					)}

					{isMobile && (
						<Grid item xs={12} md={4} lg={3}>
							<DashboardCard
								label="New Team Updates"
								icon={TeamIcon}
								isPost
								scrollToSection={scrollToSection}
							/>
						</Grid>
					)}

					{alignment !== "first" && (
						<Grid item xs={12} md={4} lg={3} style={{ cursor: "pointer" }}>
							<DashboardCard
								label={"Leaderboard"}
								onClick={() => navigate("/teamLeaderboard")}
							/>
						</Grid>
					)}

					<Modal
						open={modalOpen}
						onClose={handleClose}
						aria-labelledby="modal-title"
						aria-describedby="modal-description"
						sx={{
							display: "flex",
							alignItems: "center",
							justifyContent: "center",
						}}
					>
						<Box
							width={{ xs: "80%", sm: "60%", md: "40%" }}
							bgcolor="background.paper"
							p={4}
							boxShadow={24}
							borderRadius={1}
						>
							<Typography id="modal-title" variant="h6">
								Miles Saved
							</Typography>
							<Typography id="modal-description" sx={{ mt: 2 }}>
								The equivalent Carbon Dioxide saved displayed as miles not
								driven in a conventional gas car.
							</Typography>
						</Box>
					</Modal>
				</Grid>
			</Grid>
		</Box>
	);
};

export default DashboardCardsComponent;
