import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
	Box,
	Grid,
	useMediaQuery,
	useTheme,
	Card,
	CardContent,
	Typography,
	Chip,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import Feed from "../../components/Feed/Feed";
import LineChart from "../../components/LineChart";
import ChallengeCardLeaderboard from "./components/ChallangeCardLeaderboard";
import TableListing from "../../components/TableListing";
import {
	getTeamActiveChallangesAction,
	getLeaderboardDetailsAction,
	getLeaderboardGraphAction,
} from "../../actions/leaderboard";

const StyledCard = styled(Card)(({ isMobile }) => ({
	borderRadius: "8px",
	backgroundColor: "#FFFFFF1A",
	color: "#fff",
	minHeight: isMobile ? "auto" : "130px",
}));

const StyledChip = styled(Chip)(({ isSelected }) => ({
	backgroundColor: "transparent",
	border: `1px solid ${isSelected ? "#FF802A" : "#fff"}`,
	fontSize: "12px",
	fontFamily: "Roboto",
	fontWeight: 500,
	lineHeight: "12px",
	borderRadius: "100px",
	height: "24px",
	color: `${isSelected ? "#FF802A" : "#fff"}`,
	marginRight: "4px",
}));

const TeamLeaderboard = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down("md"));

	const teamName = useSelector((state) => state?.team?.code);
	const teamTarget = useSelector((state) => state?.team?.target);
	const [newPost, setNewPost] = useState(false);

	const [selectedFilter, setSelectedFilter] = useState("All");
	const filters = ["All", "Discovery Guides", "Action Guides", "Posts"];

	const [selectedLeaderboardFilter, setSelectedLeaderboardFilter] =
		useState("All");
	const leaderboardFilters = [
		"All",
		"Discovery Guides",
		"Action Guides",
		"Posts",
	];
	const [leaderboardData, setLeaderboardData] = useState([]);
	const [leaderboardGraph, setLeaderboardGraph] = useState([]);
	const [viewAllLeaderboardData, setViewAllLeaderboardData] = useState(false);

	const [activeChallanges, setActiveChallanges] = useState([]);
	const [viewAllChallanges, setViewAllChallanges] = useState(false);

	const getTeamActiveChallanges = () => {
		dispatch(getTeamActiveChallangesAction())
			.then((res) => {
				setActiveChallanges(
					res.sort((a, b) => a?.users?.length - b?.users?.length).reverse()
				);
			})
			.catch((err) => {
				console.log(err);
			});
	};

	const getTeamLeaderboard = () => {
		var param;
		if (selectedLeaderboardFilter === "Discovery Guides") {
			param = "discovery";
		} else if (selectedLeaderboardFilter === "Action Guides") {
			param = "action";
		} else if (selectedLeaderboardFilter === "Posts") {
			param = "posts";
		}

		dispatch(getLeaderboardDetailsAction(param))
			.then((res) => {
				const isAdminFilter = res?.filter((itm) => itm?.is_admin !== true);
				const sortedData = isAdminFilter.sort((a, b) => b.score - a.score);
				setLeaderboardData(sortedData);
			})
			.catch((err) => {
				console.log(err);
			});
	};

	const getTeamLeaderboardGraph = () => {
		var param;
		if (selectedFilter === "Discovery Guides") {
			param = "discovery";
		} else if (selectedFilter === "Action Guides") {
			param = "action";
		} else if (selectedFilter === "Posts") {
			param = "posts";
		}

		dispatch(getLeaderboardGraphAction(param))
			.then((res) => {
				setLeaderboardGraph(res);
			})
			.catch((err) => {
				console.log(err);
			});
	};

	useEffect(() => {
		getTeamActiveChallanges();
	}, []);

	useEffect(() => {
		getTeamLeaderboard();
	}, [selectedLeaderboardFilter]);

	useEffect(() => {
		getTeamLeaderboardGraph();
	}, [selectedFilter]);

	return (
		<Box
			sx={{
				padding: "10px 20px",
				display: "flex",
				justifyContent: isMobile ? "left" : "center",
				paddingTop: 4,
			}}
		>
			<Grid
				container
				direction="column"
				spacing={3}
				width={isMobile ? "auto" : "700px"}
			>
				<Grid item>
					<Typography
						fontFamily={"Inter"}
						fontWeight={900}
						fontSize={"24px"}
						color={"#fff"}
						mb={0}
						lineHeight={"29px"}
					>
						Team {teamName}
					</Typography>
				</Grid>

				<Grid item>
					<StyledCard isMobile={isMobile}>
						<CardContent>
							<Grid container direction="column" spacing={2}>
								<Grid item>
									<Box
										sx={{
											display: "flex",
											justifyContent: "space-between",
											marginBottom: 1,
										}}
									>
										<Typography
											fontFamily={"Roboto"}
											fontWeight={700}
											fontSize={"22px"}
											color={"#fff"}
											lineHeight={"24px"}
										>
											Team Points
										</Typography>

										<Typography
											fontFamily={"Roboto"}
											fontWeight={300}
											fontSize={"14px"}
											color={"#fff"}
											lineHeight={"24px"}
										>
											Goal = {teamTarget}
										</Typography>
									</Box>

									<Box
										sx={{
											display: "flex",
											flexWrap: "wrap",
											gap: "6px",
										}}
									>
										{filters?.map((filter, index) => {
											return (
												<StyledChip
													key={index}
													label={filter}
													isSelected={selectedFilter === filter}
													onClick={() => setSelectedFilter(filter)}
												/>
											);
										})}
									</Box>
								</Grid>

								<Grid item mt={1}>
									<LineChart
										yAxisData={leaderboardGraph.y_axis}
										xAxisData={leaderboardGraph.x_axis}
										maxYAxis={teamTarget}
									/>
								</Grid>
							</Grid>
						</CardContent>
					</StyledCard>
				</Grid>

				<Grid item>
					<StyledCard isMobile={isMobile}>
						<CardContent>
							<Grid container direction="column" spacing={2}>
								<Grid item>
									<Box
										sx={{
											display: "flex",
											justifyContent: "space-between",
											marginBottom: 1,
										}}
									>
										<Typography
											fontFamily={"Roboto"}
											fontWeight={700}
											fontSize={"22px"}
											color={"#fff"}
											lineHeight={"24px"}
										>
											Leaderboard
										</Typography>

										<Typography
											fontFamily={"Roboto"}
											fontWeight={300}
											fontSize={"14px"}
											color={"#fff"}
											lineHeight={"24px"}
											style={{ cursor: "pointer" }}
											onClick={() =>
												setViewAllLeaderboardData(!viewAllLeaderboardData)
											}
										>
											{viewAllLeaderboardData ? "Show Top 5" : "View All"}
										</Typography>
									</Box>

									<Box
										sx={{
											display: "flex",
											gap: "6px",
											flexWrap: "wrap",
										}}
									>
										{leaderboardFilters?.map((filter, index) => {
											return (
												<StyledChip
													key={index}
													label={filter}
													isSelected={selectedLeaderboardFilter === filter}
													onClick={() => setSelectedLeaderboardFilter(filter)}
												/>
											);
										})}
									</Box>
								</Grid>

								<Grid item>
									<TableListing
										rows={
											viewAllLeaderboardData
												? leaderboardData
												: leaderboardData?.slice(0, 5)
										}
									/>
								</Grid>
							</Grid>
						</CardContent>
					</StyledCard>
				</Grid>

				<Grid item>
					<StyledCard isMobile={isMobile}>
						<CardContent>
							<Grid container direction="column" spacing={1}>
								<Grid item>
									<Box
										sx={{
											display: "flex",
											justifyContent: "space-between",
											marginBottom: 1,
										}}
									>
										<Typography
											fontFamily={"Roboto"}
											fontWeight={700}
											fontSize={"22px"}
											color={"#fff"}
											lineHeight={"24px"}
										>
											Active Challenges
										</Typography>

										<Typography
											fontFamily={"Roboto"}
											fontWeight={300}
											fontSize={"14px"}
											color={"#fff"}
											lineHeight={"24px"}
											style={{ cursor: "pointer" }}
											onClick={() => setViewAllChallanges(!viewAllChallanges)}
										>
											{viewAllChallanges ? "Show Top 4" : "View All"}
										</Typography>
									</Box>
								</Grid>

								{viewAllChallanges ? (
									<>
										{activeChallanges?.map((itm, index) => {
											return (
												<Grid item>
													<ChallengeCardLeaderboard
														key={index}
														challenge={itm}
													/>
												</Grid>
											);
										})}
									</>
								) : (
									<>
										{activeChallanges.slice(0, 4)?.map((itm, index) => {
											return (
												<Grid item>
													<ChallengeCardLeaderboard
														key={index}
														challenge={itm}
													/>
												</Grid>
											);
										})}
									</>
								)}
							</Grid>
						</CardContent>
					</StyledCard>
				</Grid>

				<Grid item>
					<Feed
						id="feed-section"
						newPost={newPost}
						setNewPost={setNewPost}
						title={"Team Updates"}
						isLeaderboard
					/>
				</Grid>
			</Grid>
		</Box>
	);
};

export default TeamLeaderboard;
