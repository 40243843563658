import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import {
	Box,
	Button,
	Card,
	CardContent,
	Chip,
	Grid,
	IconButton,
	Typography,
	useMediaQuery,
	useTheme,
	TextField,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import StarIcon from "../../assets/icons/dashboard/star.svg";
import imageOne from "../../assets/pdf-test-images/Agency-01-What-does-Agency-mean-to-us__page-0001.jpg";
import imageTwo from "../../assets/pdf-test-images/Agency-01-What-does-Agency-mean-to-us__page-0002.jpg";
import imageThree from "../../assets/pdf-test-images/Agency-01-What-does-Agency-mean-to-us__page-0003.jpg";
import imageFour from "../../assets/pdf-test-images/Agency-01-What-does-Agency-mean-to-us__page-0004.jpg";
import imageFive from "../../assets/pdf-test-images/Agency-01-What-does-Agency-mean-to-us__page-0005.jpg";
import imageSix from "../../assets/pdf-test-images/Agency-01-What-does-Agency-mean-to-us__page-0006.jpg";
import imageSeven from "../../assets/pdf-test-images/Agency-01-What-does-Agency-mean-to-us__page-0007.jpg";
import imageEight from "../../assets/pdf-test-images/Agency-01-What-does-Agency-mean-to-us__page-0008.jpg";
import imageNine from "../../assets/pdf-test-images/Agency-01-What-does-Agency-mean-to-us__page-0009.jpg";
import CImg from "../../assets/tempImages/tempCPage.png";
import PDFStories from "../../components/PDFStories";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import { ChapterLinksConst } from "../../consts/buffer";
import {
	getChapterInfoAction,
	addChapterReviewAction,
} from "../../actions/chapter";
import ReactStars from "react-rating-stars-component";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";

const StyledButton = styled(Button)(({ isMobile }) => ({
	backgroundColor: "#bdec5b",
	padding: "15px 30px 15px 30px",
	fontSize: "16px",
	fontWeight: 600,
	borderRadius: "100px",
	color: "#000",
	"&:hover": {
		backgroundColor: "#bdec5b",
		color: "#000",
	},
	textTransform: "none",
	width: isMobile ? "100%" : "80%",
}));

const StyledButtonReview = styled(Button)(() => ({
	backgroundColor: "#bdec5b",
	padding: "10px 20px 10px 20px",
	fontSize: "14px",
	fontWeight: 600,
	borderRadius: "100px",
	color: "#000",
	"&:hover": {
		backgroundColor: "#bdec5b",
		color: "#000",
	},
	fontFamily: "Attila Sans",
	lineHeight: "19px",
}));

const StyledCard = styled(Card)(({ isMobile }) => ({
	borderRadius: "8px",
	backgroundColor: "#FFFFFF1A",
	color: "#fff",
	minHeight: isMobile ? "auto" : "115px",
	paddingBottom: "0px",
}));

const StyledChip = styled(Chip)(({ isMobile }) => ({
	backgroundColor: "#FF802A",
	fontSize: "12px",
	fontFamily: "Roboto",
	fontWeight: 500,
	lineHeight: "12px",
	borderRadius: "100px",
	height: "24px",
	color: "#fff",
}));

const StyledTextField = styled(TextField)(() => ({
	"& label": {
		color: "white",
		fontFamily: "Inter",
		fontWeight: 400,
	},
	"& label.Mui-focused": {
		color: "white",
	},
	"& .MuiOutlinedInput-root": {
		backgroundColor: "#242526",
		color: "#fff",
		border: "none",
	},
}));

const ArticleCard = ({ article }) => {
	const navigate = useNavigate();

	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down("md"));

	return (
		<a
			href={article?.url}
			target="_blank"
			style={{ color: "inherit", textDecoration: "inherit" }}
		>
			<StyledCard
				isMobile={isMobile}
				style={{ minHeight: "100%" }}
				sx={{ "&:hover": { cursor: "pointer" } }}
			>
				<CardContent>
					<Grid container spacing={2}>
						<Grid item xs={12}>
							<Typography
								fontFamily={"Roboto"}
								fontWeight={700}
								fontSize={"20px"}
								color={"#fff"}
								lineHeight={"24px"}
							>
								{article.title}
							</Typography>
						</Grid>

						<Grid item xs={12}>
							<Typography
								fontFamily={"Inter"}
								fontWeight={400}
								fontSize={"13px"}
								color={"#fff"}
								lineHeight={"19px"}
							>
								{article.description}
							</Typography>
						</Grid>
					</Grid>
				</CardContent>
			</StyledCard>
		</a>
	);
};

const Chapter = () => {
	const navigate = useNavigate();
	const { chapterId } = useParams();
	var imageArray = [
		imageOne,
		imageTwo,
		imageThree,
		imageFour,
		imageFive,
		imageSix,
		imageSeven,
		imageEight,
		imageNine,
	];

	const dispatch = useDispatch();

	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down("md"));

	const [chapterData, setChapterData] = useState(null);
	const [showAllResources, setShowAllResources] = useState(false);
	const [reviewMessage, setReviewMessage] = useState("");
	const [reviewRating, setReviewRating] = useState(-1);
	const [reviewError, setReviewError] = useState(null);
	const [showReviews, setShowReviews] = useState(false);

	const ratingChanged = (newRating) => {
		setReviewRating(newRating);
	};

	const handleAddReview = () => {
		setReviewError(null);
		if (reviewRating !== -1) {
			dispatch(addChapterReviewAction(chapterId, reviewMessage, reviewRating))
				.then((res) => {
					setReviewMessage("");
					ratingChanged(-1);

					let prevReviewLst = chapterData.reviews;
					console.log(prevReviewLst);
					console.log(res.data.review);
					prevReviewLst.push(res.data.review);

					const updatedchapterData = {
						...chapterData,
						reviews: prevReviewLst,
					};

					console.log(updatedchapterData);

					// setchapterData(updatedchapterData);
				})
				.catch((err) => {
					console.log(err);
				});
		} else {
			setReviewError("Please select a rating to add a review");
		}
	};

	const getDateTime = (dateTime) => {
		const today = new Date(dateTime);
		const dd = String(today.getDate()).padStart(2, "0");
		const mm = String(today.getMonth() + 1).padStart(2, "0");
		const yyyy = today.getFullYear();

		const hour = String(today.getHours()).padStart(2, "0");
		const minutes = String(today.getMinutes()).padStart(2, "0");

		return `${dd}/${mm}/${yyyy} ${hour}:${minutes}`;
	};

	useEffect(() => {
		window.scrollTo(0, 0);

		dispatch(getChapterInfoAction(chapterId))
			.then((res) => {
				setChapterData(res.data.Chapter);
			})
			.catch((err) => {
				console.log(err);
			});
	}, []);

	return (
		<>
			<Box
				sx={{
					padding: "10px 20px",
					display: "flex",
					justifyContent: isMobile ? "left" : "center",
					marginTop: 2,
				}}
			>
				<Grid
					container
					direction="column"
					spacing={3}
					width={isMobile ? "auto" : "700px"}
				>
					<Grid item>
						<Box
							onClick={() => navigate(-1)}
							sx={{
								display: "inline-flex",
								alignItems: "center",
								justifyContent: "center",
								"&:hover": { cursor: "pointer" },
							}}
						>
							<IconButton>
								<ArrowBackIosIcon
									style={{ color: "#fff", width: "18px", height: "18px" }}
								/>
							</IconButton>

							<Typography
								fontFamily={"Inter"}
								fontWeight={700}
								fontSize={"16px"}
								color={"#fff"}
								lineHeight={"24px"}
							>
								Back
							</Typography>
						</Box>
					</Grid>

					<Grid item>
						<StyledCard isMobile={isMobile}>
							<CardContent>
								<Grid container direction="column" spacing={2}>
									<Grid item>
										<Box
											sx={{
												display: "flex",
												justifyContent: "center",
											}}
										>
											<img
												src={chapterData?.thumbnail}
												alt=""
												style={{
													maxWidth: isMobile ? "100%" : "360px",
													borderRadius: "12px",
													maxHeight: "auto",
												}}
											/>
										</Box>
									</Grid>

									<Grid item>
										<Box
											sx={{
												display: "flex",
												alignItems: "center",
												gap: "6px",
												marginTop: "6px",
											}}
										>
											<StyledChip label="DISCOVERY" />
											<StyledChip
												label={chapterData?.theme?.toUpperCase()}
												style={{ backgroundColor: "#000" }}
											/>
										</Box>
									</Grid>

									<Grid item>
										<Typography
											fontFamily={"Roboto"}
											fontWeight={700}
											fontSize={"22px"}
											color={"#fff"}
											lineHeight={"24px"}
										>
											{chapterData?.title}
										</Typography>
									</Grid>
								</Grid>
							</CardContent>
						</StyledCard>
					</Grid>

					<Grid item>
						<StyledCard isMobile={isMobile} style={{ minHeight: "auto" }}>
							<CardContent>
								<Typography
									fontFamily={"Roboto"}
									fontWeight={700}
									fontSize={"20px"}
									color={"#fff"}
									lineHeight={"24px"}
								>
									Points
								</Typography>

								<Box
									sx={{
										display: "inline-flex",
										marginTop: "10px",
										alignItems: "center",
										justifyContent: "center",
									}}
								>
									<IconButton style={{ padding: 0, paddingRight: 6 }}>
										<img src={StarIcon} alt="" style={{ height: "20px" }} />
									</IconButton>

									<Typography
										fontFamily={"Inter"}
										fontWeight={700}
										fontSize={"18px"}
										color={"#fff"}
										lineHeight={"24px"}
									>
										{chapterData?.points}
									</Typography>
								</Box>
							</CardContent>
						</StyledCard>
					</Grid>

					{chapterData?.resource_guide_images &&
						chapterData?.resource_guide_images?.length !== 0 && (
							<Grid item mb={4} mt={1}>
								<Typography
									fontFamily={"Roboto"}
									fontWeight={700}
									fontSize={"22px"}
									color={"#fff"}
									lineHeight={"26px"}
									mb={1}
								>
									Your Guide
								</Typography>

								{/* <StyledCard isMobile={isMobile}> */}
								{/* <CardContent> */}
								<PDFStories imageArray={chapterData?.resource_guide_images} />
								{/* </CardContent> */}
								{/* </StyledCard> */}
							</Grid>
						)}

					{chapterData?.quiz_questions &&
						chapterData?.quiz_questions?.length !== 0 &&
						chapterData?.completed === false && (
							<>
								<Grid item></Grid>

								<Grid item>
									<Box
										sx={{
											display: "flex",
											justifyContent: "center",
										}}
										onClick={() => {
											dispatch({ type: "UPDATE_QUIZ", payload: chapterData });
											navigate("quiz");
										}}
									>
										<StyledButton isMobile={isMobile}>Take Quiz</StyledButton>
									</Box>
								</Grid>
							</>
						)}

					<Grid item>
						<Box
							sx={{
								display: "flex",
								justifyContent: "center",
							}}
							onClick={() => navigate("/dashboard")}
						>
							<StyledButton isMobile={isMobile}>Back to Dashboard</StyledButton>
						</Box>
					</Grid>

					<Grid item>
						<Box
							sx={{
								display: "flex",
								justifyContent: "center",
							}}
						>
							<StyledButton
								sx={{
									color: "#fff",
									backgroundColor: "#000",
									border: "#fff solid 1px",
								}}
								isMobile={isMobile}
								onClick={() => {
									navigate("/dashboard");
								}}
							>
								View Team Updates
							</StyledButton>
						</Box>
					</Grid>

					{chapterData?.resources?.length !== 0 && (
						<Grid item>
							<Typography
								fontFamily={"Roboto"}
								fontWeight={700}
								fontSize={"22px"}
								color={"#fff"}
								lineHeight={"26px"}
								mb={1}
							>
								Dive Deeper
							</Typography>

							<Grid container direction="column" spacing={2}>
								{showAllResources ? (
									<>
										{chapterData?.resources.map((article, key) => {
											return (
												<Grid item>
													<ArticleCard key={key} article={article} />
												</Grid>
											);
										})}
									</>
								) : (
									<>
										{chapterData?.resources
											?.slice(0, 3)
											?.map((article, key) => {
												return (
													<Grid item>
														<ArticleCard key={key} article={article} />
													</Grid>
												);
											})}
										<Grid item>
											<Button
												onClick={() => {
													setShowAllResources(true);
												}}
												style={{
													width: "100%",
													backgroundColor: "#FFFFFF1A",
													borderRadius: "8px",
													color: "#fff",
													paddingTop: "20px",
													paddingBottom: "10px",
												}}
											>
												Show More
											</Button>
										</Grid>
									</>
								)}
							</Grid>
						</Grid>
					)}

					<Grid item>
						<Typography
							fontFamily={"Roboto"}
							fontWeight={700}
							fontSize={"22px"}
							color={"#fff"}
							lineHeight={"26px"}
							mb={2}
						>
							Leave a review
						</Typography>

						<StyledCard>
							<CardContent>
								<Box
									sx={{
										display: "flex",
										gap: "7px",
										alignItems: "end",
										marginBottom: "15px",
									}}
								>
									<Typography
										fontFamily={"Roboto"}
										fontWeight={500}
										fontSize={"16px"}
										color={"#fff"}
										lineHeight={"26px"}
										display="inline"
									>
										Rating:
									</Typography>

									<ReactStars
										count={5}
										onChange={ratingChanged}
										size={24}
										activeColor="#ffd700"
										value={reviewRating}
									/>
								</Box>

								<StyledTextField
									multiline
									minRows={1}
									maxRows={5}
									fullWidth
									placeholder="Your review message..."
									sx={{
										border: "none",
										"& fieldset": { border: "none" },
									}}
									value={reviewMessage}
									onChange={(e) => setReviewMessage(e.target.value)}
									error={reviewError}
									helperText={reviewError}
								/>

								<Box
									mt={2}
									sx={{
										display: "flex",
										justifyContent: "right",
									}}
								>
									<StyledButtonReview onClick={handleAddReview}>
										Add Review
									</StyledButtonReview>
								</Box>
							</CardContent>
						</StyledCard>
					</Grid>

					<Grid container item direction={"column"} spacing={2}>
						{showReviews ? (
							<>
								{chapterData?.reviews?.map((review, key) => {
									return (
										<Grid key={key} item>
											<StyledCard isMobile={isMobile}>
												<CardContent>
													<Grid container spacing={1} wrap="noWrap">
														<Grid item>
															{review?.user?.avatar_url ? (
																<LazyLoadImage
																	src={review?.user?.avatar_url}
																	alt=""
																	style={{
																		color: "#fff",
																		width: "42px",
																		height: "42px",
																		borderRadius: "100px",
																	}}
																	effect="blur"
																/>
															) : (
																<AccountCircleIcon
																	sx={{
																		color: "#fff",
																		width: "50px",
																		height: "50px",
																	}}
																/>
															)}
														</Grid>

														<Grid
															container
															spacing={0}
															item
															direction={"column"}
														>
															<Grid item>
																<Box
																	sx={{
																		display: "flex",
																		alignItems: "center",
																	}}
																>
																	<Typography
																		color="#bdec5b"
																		style={{ marginRight: "10px" }}
																		fontFamily={"Roboto"}
																		fontSize={"20px"}
																		fontWeight={700}
																		textTransform={"capitalize"}
																		display="inline"
																	>
																		{review?.user?.first_name}{" "}
																		{review?.user?.last_name}
																	</Typography>

																	<Typography
																		mt={0.4}
																		fontFamily={"Roboto"}
																		fontSize={"12px"}
																		fontWeight={400}
																		color="#aaa"
																		textTransform={"capitalize"}
																		display="inline"
																	>
																		{getDateTime(review?.created_at)}
																	</Typography>
																</Box>
															</Grid>

															<Grid item style={{ marginTop: -4 }}>
																<ReactStars
																	count={5}
																	size={20}
																	edit={false}
																	activeColor="#ffd700"
																	value={review?.rating}
																/>
															</Grid>

															<Grid item>
																<Typography
																	fontFamily={"Inter"}
																	fontSize={"16px"}
																	fontWeight={400}
																	lineHeight={"26px"}
																	color={"#e4e6eb"}
																>
																	{review?.message}
																</Typography>
															</Grid>
														</Grid>
													</Grid>
												</CardContent>
											</StyledCard>
										</Grid>
									);
								})}
							</>
						) : (
							<>
								{chapterData?.reviews?.slice(0, 3)?.map((review, key) => {
									return (
										<Grid key={key} item>
											<StyledCard isMobile={isMobile}>
												<CardContent>
													<Grid container spacing={1} wrap="noWrap">
														<Grid item>
															{review?.user?.avatar_url ? (
																<LazyLoadImage
																	src={review?.user?.avatar_url}
																	alt=""
																	style={{
																		color: "#fff",
																		width: "42px",
																		height: "42px",
																		borderRadius: "100px",
																	}}
																	effect="blur"
																/>
															) : (
																<AccountCircleIcon
																	sx={{
																		color: "#fff",
																		width: "50px",
																		height: "50px",
																	}}
																/>
															)}
														</Grid>

														<Grid
															container
															spacing={0}
															item
															direction={"column"}
														>
															<Grid item>
																<Box
																	sx={{
																		display: "flex",
																		alignItems: "center",
																	}}
																>
																	<Typography
																		color="#bdec5b"
																		style={{ marginRight: "10px" }}
																		fontFamily={"Roboto"}
																		fontSize={"20px"}
																		fontWeight={700}
																		textTransform={"capitalize"}
																		display="inline"
																	>
																		{review?.user?.first_name}{" "}
																		{review?.user?.last_name}
																	</Typography>

																	<Typography
																		mt={0.4}
																		fontFamily={"Roboto"}
																		fontSize={"12px"}
																		fontWeight={400}
																		color="#aaa"
																		textTransform={"capitalize"}
																		display="inline"
																	>
																		{getDateTime(review?.created_at)}
																	</Typography>
																</Box>
															</Grid>

															<Grid item style={{ marginTop: -4 }}>
																<ReactStars
																	count={5}
																	size={20}
																	edit={false}
																	activeColor="#ffd700"
																	value={review?.rating}
																/>
															</Grid>

															<Grid item>
																<Typography
																	fontFamily={"Inter"}
																	fontSize={"16px"}
																	fontWeight={400}
																	lineHeight={"26px"}
																	color={"#e4e6eb"}
																>
																	{review?.message}
																</Typography>
															</Grid>
														</Grid>
													</Grid>
												</CardContent>
											</StyledCard>
										</Grid>
									);
								})}

								{chapterData?.reviews?.length > 3 && (
									<Grid item>
										<Button
											onClick={() => {
												setShowReviews(true);
											}}
											style={{
												width: "100%",
												backgroundColor: "#FFFFFF1A",
												borderRadius: "8px",
												color: "#fff",
												paddingTop: "20px",
												paddingBottom: "10px",
											}}
										>
											Show More
										</Button>
									</Grid>
								)}
							</>
						)}
					</Grid>

					<Grid item></Grid>
				</Grid>
			</Box>
		</>
	);
};

export default Chapter;
