import {
	Box,
	Grid,
	useMediaQuery,
	useTheme,
	Card,
	CardContent,
} from "@mui/material";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Feed from "../../components/Feed/Feed";
import { getChallangesAction } from "../../actions/challange";
import ChallengeCard from "./components/ChallengeCard";
import { ReactComponent as Blurry } from "../../assets/blurry.svg";
import DashboardCardsComponent from "./components/DashboardCardsComponent";
import blurryImg from "../../assets/blurry.png";

const StyledCard = styled(Card)(({ isMobile }) => ({
	borderRadius: "8px",
	backgroundColor: "#FFFFFF1A",
	color: "#fff",
	minHeight: isMobile ? "auto" : "130px",
	"&:hover": { cursor: "pointer" },
}));

const Dashboard = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down("md"));

	const userDetails = useSelector((state) => state.auth.user);

	const [challenges, setchallenges] = useState([]);
	const [newPost, setNewPost] = useState(false);

	const scrollToSection = (sectionId) => {
		const section = document.getElementById(sectionId);
		if (section) {
			section.scrollIntoView({
				behavior: "smooth",
				block: "start",
			});
		} else {
			console.error(`Section with ID "${sectionId}" not found.`);
		}
	};

	useEffect(() => {
		dispatch(getChallangesAction())
			.then((res) => {
				setchallenges(res.data.guides);
			})
			.catch((err) => {
				console.log(err);
			});
	}, []);

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	return (
		<Box
			sx={{
				padding: isMobile ? "24px" : "28px",
				paddingTop: 4,
			}}
		>
			<Typography
				fontFamily={"Inter"}
				fontWeight={900}
				fontSize={"24px"}
				color={"#fff"}
				mb={2}
				lineHeight={"29px"}
			>
				Hey {userDetails?.first_name}!
			</Typography>

			<DashboardCardsComponent
				isMobile={isMobile}
				scrollToSection={scrollToSection}
			/>

			<Typography
				fontFamily={"Roboto"}
				fontWeight={600}
				fontSize={"22px"}
				color={"#fff"}
				mt={4}
				mb={2}
				lineHeight={"26px"}
			>
				Your Challenges
			</Typography>

			<Grid
				container
				direction={isMobile ? "column" : "row"}
				spacing={isMobile ? 2 : 4}
				mb={4}
			>
				{challenges?.length === 0 ? (
					<>
						<Grid item xs={12} md={4} lg={3}>
							<StyledCard
								isMobile={isMobile}
								onClick={() => navigate(`/enrollGuides`)}
							>
								<CardContent>
									<Grid container spacing={2} wrap="nowrap">
										<Grid item>
											<Blurry
												style={{
													borderRadius: "12px",
													width: "85px",
													height: "auto",
												}}
											/>
										</Grid>

										<Grid item>
											<Typography
												style={{ overflowWrap: "break-word" }}
												fontFamily={"Roboto"}
												fontWeight={700}
												fontSize={"20px"}
												color={"#fff"}
												lineHeight={"24px"}
											>
												No Challenges Selected
											</Typography>

											<Typography
												fontFamily={"Inter"}
												fontWeight={500}
												fontSize={"16px"}
												color={"#fff"}
												lineHeight={"24px"}
												mt={2}
											>
												Choose your Challenges
											</Typography>
										</Grid>
									</Grid>
								</CardContent>
							</StyledCard>
						</Grid>
					</>
				) : (
					<>
						{challenges?.map((obj, key) => {
							return (
								<Grid key={obj.id} item xs={12} md={4} lg={3}>
									<ChallengeCard key={obj.id} challenge={obj} />
								</Grid>
							);
						})}

						<Grid item xs={12} md={4} lg={3} mt={0}>
							<StyledCard
								style={{ minHeight: "100%" }}
								isMobile={isMobile}
								onClick={() => navigate(`/enrollGuides`)}
							>
								<CardContent>
									<Grid
										container
										alignItems={"center"}
										spacing={2}
										wrap="nowrap"
									>
										<Grid item>
											<img src={blurryImg} alt="Custom" style={{ width: "85px", height: "auto" , borderRadius:"10px"}} />
											{/* <Blurry style={{ width: "85px", height: "auto" }} /> */}
										</Grid>

										<Grid item>
											{/* <Typography fontFamily={"Roboto"} fontWeight={700} fontSize={'20px'} color={"#fff"} lineHeight={'24px'}>
                                                            No challenges selected yet
                                                        </Typography> */}

											<Typography
												fontFamily={"Inter"}
												fontWeight={500}
												fontSize={"18px"}
												color={"#fff"}
												lineHeight={"24px"}
											>
												Edit your Challenges
											</Typography>
										</Grid>
									</Grid>
								</CardContent>
							</StyledCard>
						</Grid>
					</>
				)}
			</Grid>

			{isMobile ? (
				<>
					<Grid
						container
						direction="row"
						justifyContent={"space-between"}
						alignItems="center"
						marginBottom={2}
						marginTop={isMobile ? 0 : 2}
					>
						<Grid item>
							<Typography
								fontFamily={"Roboto"}
								fontWeight={600}
								fontSize={"22px"}
								color={"#fff"}
								lineHeight={"26px"}
							>
								Team Updates
							</Typography>
						</Grid>
					</Grid>
				</>
			) : (
				<>
					<Typography
						fontFamily={"Roboto"}
						fontWeight={600}
						fontSize={"22px"}
						color={"#fff"}
						mt={2}
						mb={0}
						lineHeight={"26px"}
					>
						Team Updates
					</Typography>
				</>
			)}

			<Grid
				container
				direction={"column"}
				spacing={isMobile ? 2 : 4}
				mb={4}
				style={{ alignItems: "center" }}
			>
				<Grid item xs={12}>
					<Feed id="feed-section" newPost={newPost} setNewPost={setNewPost} />
				</Grid>
			</Grid>
		</Box>
	);
};

export default Dashboard;
